.search-external {
    width: 100%;
    .search-input {
        margin: 12px;
        padding: 12px;
        background-color: #fff;
        border-radius: 4px;
        .text-noti {
            font-size: 12px;
            font-weight: bold;
            margin-bottom: 12px;
            font-style: italic;
        }
        .search-group {
            display: flex;
            align-items: center;
            .input-phoneNumber {
                border: 1px solid #e4e6e5;
                padding: 12px;
                font-size: 14px;
                width: 100%;
            }
            .btn-search {
                border: 1px solid blue;
                background-color: blue;
                color: #fff;
                width: 20%;
                padding: 12px;
                font-size: 14px;
                font-weight: bold;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                
                @media (max-width: 506px) {
                  width: 35%;
                }
            }
        }
    }
    .list-policy {
        margin: 32px 12px;
        .contract-code {
            display: flex;
            padding: 8px 12px;
            justify-content: space-between;
            align-items: center;
            .code {
              .title {
                font-size: 12px;
                line-height: 20px;
                color: var(--sub-name);
              }
              .value {
                font-size: 12px;
                font-weight: bold;
              }
            }
            .contract-price {
              text-align: right;
              .value {
                font-size: 14px;
                font-weight: bold;
                color: #284166;
              }
              .detail {
                font-size: 12px;
              }
            }
          }
          .customer {
            background-color: #f2f7ff;
            display: flex;
            height: 32px;
            padding: 8px 12px;
            justify-content: space-between;
            align-items: center;
            .customer-detail {
              .name {
                font-size: 14px;
                font-weight: bold;
                color: #284166;
              }
              .birthday {
                font-size: 12px;
                color: #19314e;
              }
            }
            .logo {
              width: 40px;
              height: 40px;
              display: flex;
              justify-content: flex-end;
              img {
                height: 100%;
              }
            }
          }
          .valid-date {
            display: flex;
            height: 34px;
            padding: 8px 12px;
            justify-content: space-between;
            align-items: center;
            .title {
              font-size: 12px;
              color: #677687;
            }
            .date {
              font-size: 13px;
              font-weight: bold;
              color: #284166;
            }
          }
          .action {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px 12px;
            .renew-button {
              font-size: 14px;
              color: var(--white);
              padding: 8px 16px 8px 12px;
              display: flex;
              align-items: center;
              background-color: var(--main);
              height: 34px;
              border-radius: 4px;
              img {
                margin-right: 8px;
              }
            }
            .button-info {
              img {
                width: 24px;
                height: 24px;
              }
              .info {
                margin-left: 24px;
              }
            }
          }
          .status {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 9px 16px;
            height: 18px;
            .title {
              font-size: 14px;
            }
            .status-detail {
              font-size: 14px;
            }
          }
    }
}