.row-car {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
    .col-car {
        text-align: center;
        // width: 29.16666667%;
        width: 43%;
        border: 1px solid #cccc;
        border-radius: 8px;
        padding: 5px;

        & > img {
            width: 50%;
        }
    }

    .active-col-car {
        border: 2px solid #23c197;
        color: #23c197;
        background: #e5f9f6;
    }
}

.checkbox-info-car-pickup {
    display: flex;
    align-items: center;
    button {
        margin-right: 15px;
    }
    span{
        font-weight: 500;
    }
}

.input-info-car-seats {
    margin-top: 14px;
    .title {
        font-weight: 500;
        margin-bottom: 10px;
    }
}

.select-info-car-amount-insurances {
    margin-top: 14px;
    .title {
        font-weight: 500;
        margin-bottom: 10px;
    }
}

.search-insurance-button {
    border: none;
    outline: none;
    padding: 0px;
    width: 100%;
    height: 45px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 5px;
}

.item-card-product{
    padding: 16px;
    h5 {
        margin-top: 0;
        margin-bottom: 14px;
        font-weight: 500;
    }
    h6 {
        margin-top: 0;
        margin-bottom: 14px;
        font-weight: 600;
    }
}

.card-product {
    position: relative;
    margin: 0 16px;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(20, 43, 67, 0.1);
}

.swiper-slide-item {
    padding: 16px;
    margin-bottom: 36px;

    .header-item {
      display: flex;
      margin-top: 24px;
      justify-content: center;
      min-height: 50px;
      align-items: center;
      .header-logo {
        position: absolute;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -26px;
        box-shadow: 0 2px 16px 0 rgba(20, 43, 67, 0.1);
        background-color: white;
        overflow: hidden;
        left: calc(50% - 28px);
        img {
          width: 100%;
        }
      }
      .header-name {
        display: flex;
        flex-direction: column;
        align-items: center;
        .name {
          font-size: 9px;
          line-height: 12px;
          text-transform: uppercase;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          margin-top: 4px;
          text-align: center;
        }
        .title {
          font-size: 16px;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          text-align: center;
        }
      }
    }
    .maximum-limit {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 12px;
      border-radius: 4px;
      margin-top: 12px;
      background-color: #f2f7ff;
      padding: 12px 8px;
      .title {
        font-size: 14px;
      }
      .value {
        font-size: 14px;
        font-weight: bold;
      }
    }
}

.buy-insurance {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 18px;
  .money {
    font-size: 20px;
    font-weight: bold;
    @media screen and (max-width: 374px) {
      font-size: 17px;
    }
  }
  .price {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
  }
  .unit {
    font-size: 14px;
    color: #5a6b7b;
    font-weight: bold;
    @media screen and (max-width: 374px) {
      font-size: 13px;
    }
  }
  .buy-insurance-button {
    font-size: 14px;
    color: var(--white);
    width: 108px;
    background-color: var(--main);
    height: 34px;
    border-radius: 4px;
    @media screen and (max-width: 374px) {
      width: 90px;
    }
  }
}