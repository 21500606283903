/*ContractDetailScreen*/
.contain-row {
  padding: 8px 0;
  background-color: var(--white);
}

.row {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  min-height: 36px;
  box-sizing: border-box;
  letter-spacing: normal;
  color: var(--black);
  line-height: 1.29;

  &-title {
    height: 44px;
    font-weight: bold;
    font-size: 14px;
  }

  &-left {
    align-self: flex-start;
    //width: 40%;
    //text-align: left;
  }

  &-right {
    width: 60%;
    text-align: right;
  }
}

.item-bold {
  font-weight: bold;
}
.systems-new-feature {
  display: flex;
  background-color: #ffeecc;
  align-items: center;
  border-radius: 4px;
  padding: 8px 12px;
}
.button-fixed-bottom {
  .btn-close, .btn-extend {
    font-size: 16px;
    height: 44px;
    border-radius: 4px;
  }
  .btn-close {
    font-weight: bold;
    background-color: var(--main);
    color: var(--white);
    width: 100%;
  }
  .btn-close-extend {
    background-color: var(--main-light);
    color: var(--main);
    width: 30%;
    margin-right: 4px;
    font-weight: normal;
  }
  .btn-extend {
    font-weight: bold;
    background-color: var(--main);
    color: var(--white);
    width: 70%;
    margin-left: 4px;
  }
}