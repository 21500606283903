.home-screen-container {
  margin-bottom: 122px;

  .header-home-page {
    position: sticky;
    position: -webkit-sticky;
    height: 64px;
    width: 100%;
    top: -1px;
    z-index: 2;
  }

  .home-banner-zalo {
    width: 100%;
  }

  .home-tab {
    padding-top: 8px;
    padding-bottom: 12px;
    height: 42px;
    display: flex;
    flex-direction: column;
  }

  .icon-tab {
    width: 24px;
    height: 24px;
  }

  .icon-tab-inactive {
    filter: grayscale(1);
  }

  .home-banner {
    height: 150px;
    background-size: cover;
    margin-top: 64px;

    .large-title {
      font-size: 20px;
      line-height: 24px;
      margin-top: 30px;
      margin-left: 16px;
    }

    .small-title {
      color: #435568;
      font-size: 16px;
      line-height: 14px;
      margin-top: 4px;
      margin-left: 16px;
    }
  }

  .home-swiper {
    margin-top: -50px;
  }

  .home-copyright {
    font-size: 12px;
    line-height: 14px;
  }
}

.w-50 {
  width: 50%;
}

.border-bottom-blue-2 {
  border-bottom: solid 2px #006dff;
}

.paragraph-center {
  text-align: start;
  margin: 0 16px 10px 16px;
  font-weight: bolder
}