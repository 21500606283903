.form {
    padding: 0px 16px;
    border-radius: 4px;
    background-color: var(--white);
  
    &_title {
      height: 20px;
      background-color: var(--white);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 16px;
      font-weight: bold;
      padding: 16px 0px;
    }
  
    &_row {
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
  