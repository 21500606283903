.confirm-text {
  line-height: 20px;
  font-size: 13px;
  margin-right: 5px;
  color: var(--sub-name);
  div {
    margin: 0px 0px 8px 0px !important;
    line-height: 18px !important;
  }
}
