$sizes: 2 4 6 8 10 12 14 16 24 36 40 90;

@each $size in $sizes {
    .margin-#{$size} {
      margin: #{$size}px;
    }
    .margin-h-#{$size} {
        margin-left: #{$size}px;
        margin-right: #{$size}px;
    }
    .margin-v-#{$size} {
        margin-top: #{$size}px;
        margin-bottom: #{$size}px;
    }
    .margin-top-#{$size} {
        margin-top: #{$size}px;
    }
    .margin-bottom-#{$size} {
        margin-bottom: #{$size}px;
    }
    .margin-left-#{$size} {
        margin-left: #{$size}px;
    }
    .margin-right-#{$size} {
        margin-right: #{$size}px;
    }
  }
