
.flex {
    display: flex;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-v-center {
    display: flex;
    align-items: center;
}

.flex-h-center {
    display: flex;
    justify-content: center;
}

.flex-start {
    display: flex;
    align-items: flex-start;
}

.flex-end {
    display: flex;
    align-items: flex-end;
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
}

.inline-block {
    display: inline-block;
}
  
.flex-row {
    flex-direction: row;
}
  
.flex-column {
    flex-direction: column;
}

.box-sizing-border {
    box-sizing: border-box;
}

$columns: 2 3;

@each $column in $columns {
    .grid-columns-#{$column} {
        display: grid;
        grid-template-columns: repeat(#{$column}, 1fr);
    }
}

.grid-columns {
    display: grid;
    grid-auto-flow: column;
}

.grid-gap-12 {
    grid-gap: 12px;
}


.grid-gap-8 {
    grid-gap: 8px;
}

.w-100 {
    width: 100%;
}