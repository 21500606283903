.footer-home-page {
    height: 50px;
    width: 100%;
    bottom: 0;
    z-index: 2;
    img {
        width: 24px;
        height: 24px;
    }
    .w-50 {
        width: 50%;
    }
  
    .modal-share-link { 
      display: none;
      position: fixed;
      z-index: 1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: visible; /* Enable scroll if needed */
      background-color: rgb(0,0,0);
      background-color: rgba(0,0,0,0.4);
      .modal-content {
        background-color: #fefefe;
        margin: auto;
        width: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 8px;
        .banner {
          width: 100%;
          height: 100%;
          background-color: darkcyan;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
        .content {
          text-align: center;
          display: flex;
          flex-direction: column;
          gap: 8px;
          padding: 5px 10px;
          p {
            margin: 0;
          }
          .title {
            margin: 0;
            font-size: 18px;
          }
          .input-custom {
            border: 1px solid black;
            border-radius: 4px;
            padding: 12px;
            font-size: 16px;
          }
          .list-btn {
            display: grid;
            // grid-template-columns: repeat(1, minmax(0, 1fr));
            gap: 10px;
            margin-top: 10px;
            margin-bottom: 10px;
            .btn-copy {
              border: 1px solid black;
              border-radius: 4px;
              padding: 18px 22px;
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 8px;
              .text {
                width: 100%;
                text-align: center;
                font-weight: bold;
              }
            }
            .confirm {
              border: 1px solid black;
              border-radius: 4px;
              padding: 18px 22px;
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 8px;
              .text {
                width: 100%;
                text-align: center;
                font-weight: bold;
                color: green;
              }
            }
          }
        }
      }
      .close {
        color: #aaaaaa;
        float: right;
        font-size: 28px;
        font-weight: bold;
        position: absolute;
        top: 0px;
        right: 10px;
      }
      .close:hover,
      .close:focus {
        color: #000;
        text-decoration: none;
        cursor: pointer;
      }
    }
    @media screen and (max-width: 768px) {
      .modal-share-link {
        .modal-content {
          width: 75%;
        }
      }
    }
  }