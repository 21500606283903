
.expand-dropdown {
    animation: dropdown 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.expand-dropup {
    animation: dropup 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.expand-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}

.expand-content-active {
    background-color: var(--white);
    max-height: 100%;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}

@keyframes dropdown {
    0% {transform: rotate(0);}
    100% {transform: rotate(180deg);}
}

@keyframes dropup {
    0% {transform: rotate(180deg);}
    100% {transform: rotate(0deg);}
}

.expand span,
.expand p {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    line-height: 18px;
}