$sizes: 0 4 8 10 12 14 16 24 28 76 90;

  @each $size in $sizes {
    .padding-#{$size} {
      padding: #{$size}px;
    }
    .padding-h-#{$size} {
        padding-left: #{$size}px;
        padding-right: #{$size}px;
    }
    .padding-v-#{$size} {
        padding-top: #{$size}px;
        padding-bottom: #{$size}px;
    }
    .padding-top-#{$size} {
        padding-top: #{$size}px;
    }
    .padding-bottom-#{$size} {
        padding-bottom: #{$size}px;
    }
    .padding-left-#{$size} {
        padding-left: #{$size}px;
    }
    .padding-right-#{$size} {
        padding-right: #{$size}px;
    }
  }
