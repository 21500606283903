.list-style-disc {
    list-style-type: disc;
    text-align: justify;
}
.list-style-decimal {
    list-style-type: decimal;
    text-align: justify;
}
.list-style-none {
    list-style-type: none;
    text-align: justify;
}