.custom-innerHTML {
  p, li, span {
    margin: 0px 0px 8px 0px !important;
    line-height: 18px !important;
  }
  ul {
    padding-left: 20px !important;
    margin-top: 0 !important;
  }
  .benefit-item {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
    .radio-active {
      margin-right: 8px;
      display: block;
    }
  }
}
