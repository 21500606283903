@import "./css/background";
@import "./css/border";
@import "./css/color";
@import "./css/font";
@import "./css/image";
@import "./css/layout";
@import "./css/margin";
@import "./css/padding";
@import "./css/position";

:root {
  --main: #006dff;
  --main-light: #e6f0ff;
  --black: #142b43;
  --sub-name: #435568;
  --text-main: #24272B;
  --text-color: #727f8c;
  --gray: #8995a1;
  --disable: #8995a1;
  --white: #ffffff;
  --border: #e4e6e5;
  --border-light: #f3f4f5;
  --border-dark: #e7eaec;
  --background: #f6f7f8;
  --blue: #0057cc;
  --green: #15b34e;
  --transparent: transparent;
  --red: #ff5630;
  --button-disable: #dbdfe2;
  --button-height: 44px;
  --tab-height: 48px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f7f8
}

:root {
  --border-dark: #e7eaec;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

button {
  outline: none;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
}

button:hover {
  cursor: pointer;
}

input {
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

[contenteditable]:focus {
  outline: 0px solid transparent;
}

[data-placeholder]:empty:before {
  content: attr(data-placeholder);
  color: #8995a1;
}

p > img {
  width: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

figure.image > img {
  width: 100%;
}

figure.image {
  margin: 0;
}

.button-fixed-bottom {
  width: 100%;
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 76px;
  padding: 16px;
  .price {
    font-size: 18px;
    font-weight: bold;
  }
}

.btn-main {
  font-size: 16px;
  font-weight: bold;
  color: var(--white);
  width: 60%;
  background-color: var(--main);
  height: 44px;
  border-radius: 4px;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color: #8995a1;
}

:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: #8995a1;
  opacity: 1;
}

::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #8995a1;
  opacity: 1;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #8995a1;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #8995a1;
}

::placeholder { /* Most modern browsers support this now. */
  color: #8995a1;
}

.rotate-counter-clockwise-90{
  transform: rotate(-90deg);
}

.loader {
  width: 60px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side,#1c6dc5 90%,#0000);
  background: 
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 50%;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
    20%{background-position:0%   0%, 50%  50%,100%  50%}
    40%{background-position:0% 100%, 50%   0%,100%  50%}
    60%{background-position:0%  50%, 50% 100%,100%   0%}
    80%{background-position:0%  50%, 50%  50%,100% 100%}
}

.custom-modal-captcha {
  .head-modal {
    padding: 22px;
    border-bottom: 1px solid #aaaa;
    .head-modal-title {
      font-size: 22px;
      font-weight: bold;
    }
  }
  .body-modal {
    margin: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    .body-modal-captcha {
      max-width: 485px;
      width: 100%;
      border-radius: 7px;
      .captcha-area {
        display: flex;
        height: 65px;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 25px;
        .captcha-img {
          height: 100%;
          width: 345px; 
          user-select: none;
          background: #000;
          border-radius: 5px;
          position: relative;

          @media (max-width: 506px) {
            width: 250px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 5px;
            opacity: 0.95;
          }
          .captcha {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 100%;
            color: #fff;
            font-size: 35px;
            text-align: center;
            letter-spacing: 20px;
            transform: translate(-50%, -50%);
            text-shadow: 0px 0px 2px #b1b1b1;

            @media (max-width: 506px) {
              letter-spacing: 10px;
            }
          }
        }
        .reload-btn {
          width: 75px;
          height: 100%;
          font-size: 35px;

          @media (max-width: 506px) {
            width: 60px;
            margin-left: 5px;
            font-size: 20px;
          }
        }
      }
      .input-area {
        height: 60px;
        width: 100%;
        position: relative;
        &::selection {
          color: #fff;
          background: #4db2ec;
        }

        input {
          width: 100%;
          height: 100%;
          outline: none;
          padding-left: 20px;
          font-size: 20px;
          border-radius: 5px;
          border: 1px solid #bfbfbf;
          box-sizing: border-box;
          &:is(:focus, :valid) {
            padding-left: 19px;
            border: 2px solid #4db2ec;
            &:valid + .btn-check {
              opacity: 1;
              pointer-events: auto;
            }
          }
        }

        .btn-check {
          position: absolute;
          right: 7px;
          top: 50%;
          font-size: 17px;
          height: 45px;
          padding: 0 20px;
          opacity: 0;
          pointer-events: none;
          transform: translateY(-50%);

          @media (max-width: 506px) {
            .check-btn {
              height: 40px;
            }
          }
        }

        @media (max-width: 506px) {
          height: 55px;
        }
      }
    }
    button {
      outline: none;
      border: none;
      color: #fff;
      cursor: pointer;
      background: #4db2ec;
      border-radius: 5px;
      transition: all 0.3s ease;
    }
  }
}