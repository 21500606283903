.qa-screen {
    .qa-item{
        height: 40px;
        border-bottom: 1px solid var(--background);
        padding: 0px 12px;
        &:last-child {
            margin-top: 8px;
            font-weight: bold;
            border-radius: 4px;
        }
        &:first-child {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }
        &:nth-last-child(2) {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }
}