.select-input {
    display: flex;
    background-color: var(--white);
    border: 1px solid var(--border);
    border-radius: 4px;
    overflow: hidden;
    height: 47px;
    width: 100%;
    box-sizing: border-box;
    &-picker {
      outline: none !important;
      padding: 0 12px !important;
      width: 100%;
      display: flex;
      justify-content: space-between;
      color: var(--text-main);
      font-size: 14px;
      align-items: center;
    }
    &-placeholder {
    font-size: 14px;
    color: var(--gray);
    text-align: start;
    }
    &-container {
      background-color: var(--white);
      display: flex;
      flex-direction: column;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    &-title {
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: var(--background);
    }
    &-title-text {
      padding: 10px 16px;
      text-align: center;
      font-size: 14px;
      color: var(--black);
      font-weight: bold;
    }
    &-loading {
      padding: 16px 16px 24px;
      background-color: var(--background);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      margin: -80px;
      &-title {
        text-align: center;
        margin-top: 10px;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        font-weight: bold;
        color: var(--blue)
      }
    }
    &-content {
      padding: 0 16px 110px;
      background-color: var(--background);
      height: 100%;
      overflow: auto;
      box-sizing: border-box;
    }
    &-wrap-input {
      display: flex;
      align-items: center;
      width: 100%;
      position: relative;
      padding: 6px 16px 10px;
      box-sizing: border-box;
      background-color: var(--background);
    }
    &-input-container {
      display: flex;
      background-color: var(--white);
      align-items: center;
      padding-left: 10px;
      border-radius: 4px;
      width: 100%;
      overflow: hidden;
    }
    &-input-icon {
      overflow: hidden;
      width: 18px;
      height: 18px;
    }
    &-item {
      display: flex;
      flex-direction: row;
      padding: 12px;
      background-color: var(--white);
      margin-bottom: 1px;
      justify-content: space-between;
      svg {
        width: 14px;
        height: 14px;
      }
    }
    &-item-label {
      font-size: 14px;
      color: var(--text-main);
    }
    &-content-list {
      border-radius: 4px;
      overflow: hidden;
    }
  }