.pos-absolute {
    position: absolute;
}

.pos-relative {
    position: relative;
}

.pos-fixed {
    position: fixed;
}