.ticket {
    width: 100%;
    height: auto;
    background-color: transparent !important;
    overflow: hidden;
}
.ticket-section-info {
    height: auto;
    background-color: white;
    border-top: 1px solid var(--border-dark);
    border-right: 1px solid var(--border-dark);
    border-left: 1px solid var(--border-dark);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.ticket-section-action {
    height: auto;
    background-color: white;
    border-bottom: 1px solid var(--border-dark);
    border-right: 1px solid var(--border-dark);
    border-left: 1px solid var(--border-dark);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
.ticket-divider-notch {
    height: 20px;
    width: 100%;
    display: flex;
    position: relative;
    background-color: white;
}
.ticket-divider {
    height: 50%;
    width: 100%;
    border-bottom: 1px dashed var(--border-dark);
}
.ticket-notch {
    position: absolute;
    left: -10px;
    top: -1px;
    width: 20px;
    height: 20px;
    border: 1px solid var(--border-dark);
    border-radius: 20px;
    overflow: hidden;
}

.ticket-notch.ticket-notch-right {
    left: auto;
    right: -10px;
}