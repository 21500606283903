.management-contract {
  margin-bottom: 70px;
  .header-management-page {
    height: 50px;
    position: fixed;
    width: 100%;
    display: flex;
    top: 0;
    z-index: 2;
    .management-tab {
      width: 43%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .management-filter {
      width: 14%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-right: 16px;
      .icon-filter {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 32px;
        height: 32px;
      }
    }
  }
  .management-content {
    margin-top: 50px;
    padding: 16px;
  }
  .unit {
    color: #19314e;
    font-size: 13px;
  }
  .wrap-ticket {
    margin-top: 12px;
  }
  .contract-code {
    display: flex;
    padding: 8px 12px;
    justify-content: space-between;
    align-items: center;
    .code {
      .title {
        font-size: 12px;
        line-height: 20px;
        color: var(--sub-name);
      }
      .value {
        font-size: 12px;
        font-weight: bold;
      }
    }
    .contract-price {
      text-align: right;
      .value {
        font-size: 14px;
        font-weight: bold;
        color: #284166;
      }
      .detail {
        font-size: 12px;
      }
    }
  }
  .customer {
    background-color: #f2f7ff;
    display: flex;
    height: 32px;
    padding: 8px 12px;
    justify-content: space-between;
    align-items: center;
    .customer-detail {
      .name {
        font-size: 14px;
        font-weight: bold;
        color: #284166;
      }
      .birthday {
        font-size: 12px;
        color: #19314e;
      }
    }
    .logo {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: flex-end;
      img {
        height: 100%;
      }
    }
  }
  .valid-date {
    display: flex;
    height: 34px;
    padding: 8px 12px;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 12px;
      color: #677687;
    }
    .date {
      font-size: 13px;
      font-weight: bold;
      color: #284166;
    }
  }
  .action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    .renew-button {
      font-size: 14px;
      color: var(--white);
      padding: 8px 16px 8px 12px;
      display: flex;
      align-items: center;
      background-color: var(--main);
      height: 34px;
      border-radius: 4px;
      img {
        margin-right: 8px;
      }
    }
    .button-info {
      img {
        width: 24px;
        height: 24px;
      }
      .info {
        margin-left: 24px;
      }
    }
  }
  .status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 16px;
    height: 18px;
    .title {
      font-size: 14px;
    }
    .status-detail {
      font-size: 14px;
    }
  }
  .info-auto-renew {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .info {
      display: flex;
      align-items: center;
    }
    .info-detail {
      margin-left: 9px;
    }
    .detail,
    .payment-method {
      font-size: 13px;
    }
  }
  .product-name {
    padding-left: 12px;
  }
}
