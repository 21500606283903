::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    #7a8692;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color:    #7a8692;
    opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
    color:    #7a8692;
    opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:    #7a8692;
}
::-ms-input-placeholder { /* Microsoft Edge */
    color:    #7a8692;
}

::placeholder { /* Most modern browsers support this now. */
    color:    #7a8692;
}