$sizes: 1 4 6 8 10 12 16 20 30 35 40;

@each $size in $sizes {
    .border-radius-#{$size} {
        border-radius: #{$size}px;
    }
    .border-top-radius-#{$size} {
        border-top-left-radius: #{$size}px;
        border-top-right-radius: #{$size}px;
    }
    .border-left-radius-#{$size} {
        border-top-left-radius: #{$size}px;
        border-bottom-left-radius: #{$size}px;
    }
    .border-right-radius-#{$size} {
        border-top-right-radius: #{$size}px;
        border-bottom-right-radius: #{$size}px;
    }
}

.border-bottom-1 {
    border-bottom: solid 1px #e7eaec;
}

.border-top-1 {
    border-top: solid 1px #e7eaec;
}

.border-white-1 {
    border: solid 1px white;
}

.border-gray-1 {
    border: solid 1px #e7eaec;
}

.border-blue-1 {
    border: solid 1px #006dff;
}
.border-blue-light-1 {
    border: solid 1px #cce1ff;
}

.border-pink-1 {
    border: solid 1px #fee3ed;
}



.box-shadow-black {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.box-shadow-blue {
    box-shadow: 0 15px 15px -9px rgba(0, 109, 255, 0.3);
}