.form-group {
    position: relative;
}

.form-control {
    border: 1px solid transparent;
}

.form-control-placeholder {
    pointer-events:none;
    position: absolute;
    top: 25%;
    transition: all 200ms;
}

.form-control:disabled + .form-control-placeholder,
.form-control:focus + .form-control-placeholder,
.form-control:valid + .form-control-placeholder {
    font-size: 11px !important;
    transform: translate3d(0, -70%, 0);
}

.form-control:focus ,
.form-control:disabled ,
.form-control:valid  {
    transform: translate3d(0, 30%, 0);
}

input:-webkit-autofill {
    background-color: white !important;
    border-color: white !important;
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    #7a8692;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color:    #7a8692;
    opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
    color:    #7a8692;
    opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:    #7a8692;
}
::-ms-input-placeholder { /* Microsoft Edge */
    color:    #7a8692;
}

::placeholder { /* Most modern browsers support this now. */
    color:    #7a8692;
}