.btn-contact {
    position: fixed;
    z-index: 1;
    background-color: #454f7b;
    border-radius: 30px;
    right: 16px;
    cursor: pointer;
    button {
        padding: 7px 25px;
        margin: 0px;
        line-height: 0px;
        display: flex;
        align-items: center;
        gap: 8px;
        color: #fff;
        font-weight: bold;
        font-size: 14px;
    }
}
