body.react-confirm-alert-body-element {
    overflow: hidden;
}

.react-confirm-alert-blur {
    filter: url(#gaussian-blur);
    filter: blur(2px);
    -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
    position: fixed;
    /* padding: 10; */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    background: rgba(36, 39, 43, 0.5);
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;
    opacity: 0;
    -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-overlay > .react-confirm-alert{
    display: flex;
    width: 100%;
    justify-content: center;
}

.react-confirm-alert-body {
    font-family: Arial, Helvetica, sans-serif;
    width: 85%;
    /*padding: 30px;*/
    text-align: left;
    background: #fff;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.13);
    color: #666;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.react-confirm-alert-svg {
    position: absolute;
    top: 0;
    left: 0;
}

.react-confirm-alert-body > h1 {
    margin-top: 0;
}

.react-confirm-alert-body > h3 {
    margin: 0;
    font-size: 16px;
}

.react-confirm-alert-button-group {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: flex-start;
    /*margin-top: 20px;*/
    width: 100%;
}

.confirm-alert-icon {
    width: 50px;
    height: 50px;
    padding-top: 15px;
}
.confirm-alert-message {
    margin: 20px;
    font-size: 15px;
    color: #24272b;
    text-align: center;
}

.react-confirm-alert-button-group > button {
    outline: none;
    background: #3689ff;
    border: none;
    display: flex;
    padding: 15px;
    color: #ffffff;
    /*margin-right: 10px;*/
    /*border-radius: 5px;*/
    font-size: 14px;
    cursor: pointer;
    flex-grow: 1;
    margin: 0;
    justify-content: center;
}

.react-confirm-alert-button-group > button.button-gray {
    background: #f0f4f6;
    color: #727f8c;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-moz-keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-o-keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}