.swiper-slide-homepage {
  width: calc(100% - 48px);
  border-radius: 6px;
  margin-top: 30px;
}

.card-product {
  position: relative;
  margin: 0 16px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(20, 43, 67, 0.1);
}
.home-swiper {
  .swiper-pagination {
    bottom: 0px;
    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background: var(--gray);
    }
    .swiper-pagination-bullet-active {
      width: 24px;
      height: 8px;
      border-radius: 4px;
      background: var(--main);
    }
  }
  .swiper-wrapper {
    padding-bottom: 24px;
  }
  .swiper-slide-item {
    padding: 16px;
    margin-bottom: 36px;

    .header-item {
      display: flex;
      margin-top: 24px;
      justify-content: center;
      min-height: 50px;
      align-items: center;
      .header-logo {
        position: absolute;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -26px;
        box-shadow: 0 2px 16px 0 rgba(20, 43, 67, 0.1);
        background-color: white;
        overflow: hidden;
        left: calc(50% - 28px);
        img {
          width: 100%;
        }
      }
      .header-name {
        display: flex;
        flex-direction: column;
        align-items: center;
        .name {
          font-size: 9px;
          line-height: 12px;
          text-transform: uppercase;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          margin-top: 4px;
          text-align: center;
        }
        .title {
          font-size: 16px;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          text-align: center;
        }
      }
    }
    .maximum-limit {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 12px;
      border-radius: 4px;
      margin-top: 12px;
      background-color: #f2f7ff;
      padding: 12px 8px;
      .title {
        font-size: 14px;
      }
      .value {
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
  .benefit {
    margin-top: 16px;
    .title {
      font-weight: bold;
      font-size: 14px;
    }
    .list-benefit {
      margin-top: 8px;
    }
    .benefit-item {
      .radio-active {
        img {
          width: 12px;
          height: 12px;
          margin-top: 6px;
        }
      }
      .content {
        font-size: 14px;
        line-height: 24px;
        margin-left: 6px;
        color: #727f8e;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    .detail {
      display: flex;
      align-items: center;
      font-size: 14px;
      margin-top: 8px;
      margin-left: 18px;
      img {
        margin-left: 8px;
      }
    }
  }
  .buy-insurance {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 18px;
    .money {
      font-size: 20px;
      font-weight: bold;
      @media screen and (max-width: 374px) {
        font-size: 17px;
      }
    }
    .price {
      margin: 0;
      font-size: 18px;
      font-weight: bold;
    }
    .unit {
      font-size: 14px;
      color: #5a6b7b;
      font-weight: bold;
      @media screen and (max-width: 374px) {
        font-size: 13px;
      }
    }
    .buy-insurance-button {
      font-size: 14px;
      color: var(--white);
      width: 108px;
      background-color: var(--main);
      height: 34px;
      border-radius: 4px;
      @media screen and (max-width: 374px) {
        width: 90px;
      }
    }
  }
}
