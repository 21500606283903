.insurance-summary {
  .insurance-summary-wrapper {
    flex-wrap: wrap;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(74, 144, 226, 0.3);
    padding-bottom: 24px;
  }
  .logo {
    position: absolute;
    width: 46px;
    height: 46px;
    padding: 5px;
    left: calc(50% - 23px);
    top: -28px;
    box-shadow: 0 2px 10px 0 rgba(74, 144, 226, 0.3);
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 58px;
    }
  }
  .title-insurance {
    font-size: 16px;
    font-weight: bold;
    display: flex;
    margin-top: 22px;
    justify-content: center;
    width: 100%;
    text-align: center;
  }
  .company-name {
    font-size: 11px;
    display: flex;
    margin-top: 4px;
    justify-content: center;
    width: 100%;
    text-transform: uppercase;
  }
  .maximum-limit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 12px;
    border-radius: 4px;
    margin-top: 20px;
    background-color: #f2f7ff;
    padding: 12px 8px;
    width: 100%;
    .title {
      font-size: 14px;
    }
    .value {
      font-size: 14px;
      font-weight: bold;
    }
  }
  .price-time {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin-top: 14px;
  }
}
