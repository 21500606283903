.house-product {
    position: relative;
    margin: 0 16px;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(20, 43, 67, 0.1);
    padding: 16px;
    .info-house-product{
        h5 {
            margin-top: 0;
            margin-bottom: 14px;
            font-weight: 500;
        }
        h6 {
            margin-top: 0;
            margin-bottom: 14px;
            font-weight: 600;
        }
        .form-row {
            p {
                margin-right: 30px;
                font-weight: 600;
            }
            .title {
                margin-bottom: 10px;
                font-weight: 600;
            }
            .row-house {
                display: flex;
                justify-content: space-between;
                margin-bottom: 14px;
                flex-wrap: wrap;
                row-gap: 8px;
                .col-house {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 12px;
                    width: 46%;
                    border: 1px solid #cccc;
                    border-radius: 8px;
                    padding: 5px;
            
                    & > img {
                        width: 30%;
                    }
                }
            
                .active-col-house {
                    border: 2px solid #23c197;
                    color: #23c197;
                    background: #e5f9f6;
                }
            }
        }
        .form-radio {
            display: flex;
            align-items: center;
            gap: 12px;
            .input-radio {
                display: flex;
                align-items: center;
                gap: 4px;
                input {
                    appearance: none;
                    width: 13px;
                    height: 13px;
                    border: 1px solid #aaa;
                    border-radius: 50%;
                    position: relative;
                }
                input:checked {
                    &::after {
                        content: "";
                        position: absolute;
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        color: #007aff;
                        border: 1px solid #007aff;
                        background-color: #007aff;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
                label {
                    font-size: 14px;
                    margin-bottom: 3px;
                }
            }
        }
    }
    .search-insurance-button {
        border: none;
        outline: none;
        padding: 0px;
        width: 100%;
        height: 45px;
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        border-radius: 5px;
    }

    .item-card-product{
        padding: 16px;
        h5 {
            margin-top: 0;
            margin-bottom: 14px;
            font-weight: 500;
        }
        h6 {
            margin-top: 0;
            margin-bottom: 14px;
            font-weight: 600;
        }
    }
    
    .card-product {
        position: relative;
        margin: 0 16px;
        border-radius: 8px;
        background-color: white;
        box-shadow: 0 2px 4px 0 rgba(20, 43, 67, 0.1);
    }
    
    .swiper-slide-item {
        padding: 16px;
        margin-bottom: 36px;
    
        .header-item {
          display: flex;
          margin-top: 24px;
          justify-content: center;
          min-height: 50px;
          align-items: center;
          .header-logo {
            position: absolute;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: -26px;
            box-shadow: 0 2px 16px 0 rgba(20, 43, 67, 0.1);
            background-color: white;
            overflow: hidden;
            left: calc(50% - 28px);
            img {
              width: 100%;
            }
          }
          .header-name {
            display: flex;
            flex-direction: column;
            align-items: center;
            .name {
              font-size: 9px;
              line-height: 12px;
              text-transform: uppercase;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              margin-top: 4px;
              text-align: center;
            }
            .title {
              font-size: 16px;
              font-weight: bold;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              text-align: center;
            }
          }
        }
        .maximum-limit {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 12px;
          border-radius: 4px;
          margin-top: 12px;
          background-color: #f2f7ff;
          padding: 12px 8px;
          .title {
            font-size: 14px;
          }
          .value {
            font-size: 14px;
            font-weight: bold;
          }
        }
    }
    
    .buy-insurance {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 18px;
      .money {
        font-size: 20px;
        font-weight: bold;
        @media screen and (max-width: 374px) {
          font-size: 17px;
        }
      }
      .price {
        margin: 0;
        font-size: 18px;
        font-weight: bold;
      }
      .unit {
        font-size: 14px;
        color: #5a6b7b;
        font-weight: bold;
        @media screen and (max-width: 374px) {
          font-size: 13px;
        }
      }
      .buy-insurance-button {
        font-size: 14px;
        color: var(--white);
        width: 108px;
        background-color: var(--main);
        height: 34px;
        border-radius: 4px;
        @media screen and (max-width: 374px) {
          width: 90px;
        }
      }
    }
}