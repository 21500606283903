.modal-content-renew {
  height: 100%;
  background-color: #ffffff;
  padding-bottom: 1px;
  margin-bottom: 12px;
  .insurance-detail {
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 12px;
  }
  .logo {
    display: flex;
    img {
      width: 48px;
      height: 48px;
    }
  }
  .title {
    font-size: 16px;
    font-weight: bold;
    color: #142b43;
  }
  .month {
    font-size: 13px;
    color: #142b43;
    font-weight: normal;
  }
  .text {
    font-size: 13px;
    color: #142b43;
  }
  .price {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
    font-size: 14px;
    font-weight: bold;
    color: #006dff;
    margin: 0;
  }
  .address-input {
    margin: 0px 16px 12px 16px;
    width: auto;
  }
  .first-time-auto-debit {
    margin: 0px 16px 12px 16px;
    font-size: 11px;
    font-weight: normal;
    color: #838da5;
  }
  .wallet {
    display: flex;
    margin: 12px 16px;
    border-radius: 4px;
    border: 1px solid #e7eaec;
    height: 54px;
    align-items: center;
    padding: 0px 12px;
    justify-content: space-between;
    .icon-wallet {
      display: flex;
      align-items: center;
      img {
        margin-right: 10px;
      }
    }
  }
}
.action-renew {
  display: flex;    display: flex;
  padding: 12px 16px;
  background-color: #ffff;
  .left-button {
    width: 30%;
    margin-right: 4px;
    height: 44px;
    border-radius: 4px;
    background-color: #e6f0ff;
    font-size: 16px;
    font-weight: normal;
    text-align: center;
    color: #006dff;
  }
  .right-button {
    width: 70%;
    margin-left: 4px;
    height: 44px;
    border-radius: 4px;
    background-color: #006dff;
    font-size: 16px;
    font-weight: normal;
    text-align: center;
    color: #ffffff;
  }
}

div.address-input-content-renew {
  height: auto;
}

.cancel-autodebit {
  .img-cancel-autodebit {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }
  .title-cancel-autodebit {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-top: 8px;
  }
  .content-cancel-autodebit {
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    margin-top: 16px;
    width: 90%;
    margin-left: 5%;
    margin-bottom: 8px;
  }
}