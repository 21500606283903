
$sizes: 10 11 12 13 14 16 18 20;
$lineHeights: 12 14 16 18 20 22 24;

.font {
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

@each $lineHeight in $lineHeights {
    .line-height-#{$lineHeight} {
        line-height: #{$lineHeight}px;
    }
}

.text-align-center {
    text-align: center;
}

.text-align-justify {
    text-align: justify;
}

.text-align-left {
    text-align: left;
}

.text-overflow-ellipsis {
    text-overflow: ellipsis;
}

.text-underline {
    text-decoration: underline;
}

@each $size in $sizes {
    .font-size-#{$size} {
        font-size: #{$size}px;
    }
}

.font-weight-bold {
    font-weight: bold;
}

.font-weight-medium {
    font-weight: 500;
}

.no-wrap {
    white-space: nowrap; 
}
.pre-line {
    white-space: pre-line;
}
.break-word {
    word-break: break-word;
}




