.bg {
    background-color: #f6f7f8;
}

.bg-white {
    background-color: white;
}

.bg-gray {
    background-color: #8995a1;
}

.bg-gray-light {
    background-color: #e7eaec;
}
  
.bg-blue {
    background-color: #006dff;
}

.bg-blue-light {
    background-color: #e6f0ff;
}

.bg-blue-medium {
    background-color: #338aff;
}

.bg-green {
    background-color: #15b34e;
}

.bg-green-light {
    background-color: #d0efdb;
}

.bg-pink {
    background-color: #e866aa;
}

.bg-pink-light {
    background-color: #fff7fa;
}
.bg-modal {
    background-color: rgba(20, 43, 67, 0.5);
}

.bg-disabled {
    background-color: #dbdfe2;
}