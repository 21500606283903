.color-black {
    color: #142b43;
}
  
.color-gray {
    color: #8995a1;
}

.color-white {
    color: white;
}

.color-blue {
    color: #006dff;
}

.color-green {
    color: #15b34e;
}

.color-green-light {
    color: #d0efdb;
}

.color-orange {
    color: #ffab00;
}